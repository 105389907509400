<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-tabs v-model="active" color="transparent">
        <v-tab>Account Receivables</v-tab>
        <v-tab-item class="pa-1 pt-2">
          <company-bills />
        </v-tab-item>
      </v-tabs>
    </v-flex>
  </v-layout>
</template>

<script>
import CompanyBills from '../../components/company/CompanyBills';

export default {
  props: [],
  data() {
    return {
      active: '',
    };
  },
  components: {
    CompanyBills,
  },
};
</script>
